/* eslint-disable no-inline-styles/no-inline-styles */
import { Divider, ShimmerBox } from '@/core/features';

import styles from './spot-results-desktop-v4-loading-tile.module.scss';
export type SpotResultsDesktopV4LoadingTilesProps = {
    count?: number;
    hasTopLivePrices?: boolean;
};

export default function SpotResultsDesktopV4LoadingTiles({
    count = 10,
    hasTopLivePrices,
}: SpotResultsDesktopV4LoadingTilesProps) {
    return (
        <div className={'flex-column gap-15'}>
            {Array.from({ length: count }).map((_, index) => (
                <div
                    className={'flex gap-5'}
                    key={index}
                >
                    <div
                        className={'flex width-100 border border-alto border-radius-5 overflow-hidden'}
                        style={{ height: 307, minWidth: 538 }}
                    >
                        <div className={styles.imageContainer}>
                            <ShimmerBox borderRadius={'0px'} />
                        </div>
                        <div className={`${styles.contentDetails} flex-column gap-10 width-100 padding-15`}>
                            <div className={'flex-space-between-horizontal'}>
                                <div className={'flex-column gap-10'}>
                                    <ShimmerBox
                                        borderRadius={'5px'}
                                        height={'20px'}
                                        width={'140px'}
                                    />
                                    <ShimmerBox
                                        borderRadius={'5px'}
                                        height={'13px'}
                                        width={'110px'}
                                    />
                                </div>
                                <div className={'flex gap-10'}>
                                    <div className={'flex-center'}>
                                        <ShimmerBox
                                            borderRadius={'5px'}
                                            height={'13px'}
                                            width={'90px'}
                                        />
                                    </div>

                                    <ShimmerBox
                                        borderRadius={'5px'}
                                        height={'40px'}
                                        width={'40px'}
                                    />
                                </div>
                            </div>
                            <div className={'flex gap-10'}>
                                <ShimmerBox
                                    borderRadius={'5px'}
                                    height={'20px'}
                                    width={'120px'}
                                />
                                <ShimmerBox
                                    borderRadius={'5px'}
                                    height={'20px'}
                                    width={'80px'}
                                />
                            </div>
                            <div className={'margin-top-10'}>
                                <ShimmerBox
                                    borderRadius={'5px'}
                                    height={'12px'}
                                    width={'80px'}
                                />
                            </div>
                            <div className={'flex-start gap-5'}>
                                {Array.from({ length: 12 }).map((_, index) => (
                                    <ShimmerBox
                                        height={'20px'}
                                        key={index}
                                        width={'20px'}
                                    />
                                ))}
                            </div>
                            <div className={'flex-column height-100 gap-10 margin-top-10'}>
                                <ShimmerBox
                                    borderRadius={'5px'}
                                    height={'18px'}
                                    width={'140px'}
                                />
                                <ShimmerBox
                                    borderRadius={'5px'}
                                    height={'18px'}
                                    width={'80px'}
                                />
                            </div>
                            <div className={'flex-end'}>
                                <ShimmerBox
                                    borderRadius={'5px'}
                                    height={'40px'}
                                    width={'200px'}
                                />
                            </div>
                        </div>
                    </div>
                    {hasTopLivePrices && (
                        <div
                            className={
                                'flex-space-between-vertical gap-10 padding-15 border border-alto border-radius-5'
                            }
                            style={{ minWidth: 122 }}
                        >
                            <div className={'flex-center-vertical gap-10'}>
                                <ShimmerBox
                                    borderRadius={'5px'}
                                    height={'25px'}
                                    width={'30px'}
                                />
                                <ShimmerBox
                                    borderRadius={'5px'}
                                    height={'25px'}
                                />
                            </div>
                            <Divider color={'alto'} />
                            <div className={'flex-center-vertical gap-10'}>
                                <ShimmerBox
                                    borderRadius={'5px'}
                                    height={'25px'}
                                    width={'30px'}
                                />
                                <ShimmerBox
                                    borderRadius={'5px'}
                                    height={'25px'}
                                />
                            </div>
                            <Divider color={'alto'} />
                            <div className={'flex-center-vertical gap-10'}>
                                <ShimmerBox
                                    borderRadius={'5px'}
                                    height={'25px'}
                                    width={'30px'}
                                />
                                <ShimmerBox
                                    borderRadius={'5px'}
                                    height={'25px'}
                                />
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
