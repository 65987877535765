import type { SpotResultHorizontalDesktopAttributes } from '@/features/spot-results/spot-results-desktop-v4/spot-results-desktop-v4-type';

import React from 'react';

import Divider from '@/core/components/divider/divider';
import { HtmlTextWrapper, IconImage, Link } from '@/core/features';
import { useRouterLink } from '@/core/features/router/router-link';
import color from '@/core/features/styles/color';
import SpotResultHorizontalDesktop from '@/features/spot-results/spot-results-desktop-v4/spot-result-desktop/spot-result-horizontal-desktop';

type SpotResultHorizontalDesktopProps = SpotResultHorizontalDesktopAttributes;

export default function SpotResultHorizontalContainerDesktop({
    link,
    subtitle,
    tiles,
    title,
    topLivePrices,
}: SpotResultHorizontalDesktopProps) {
    const [isTopLivePriceOpen, setTopLivePriceOpen] = React.useState(false);
    const { navigate } = useRouterLink();

    const singleCtaLink =
        topLivePrices?.ctaList.length === 1 && topLivePrices.ctaList[0] ? topLivePrices.ctaList[0].linkUrl : null;

    return (
        <div className={'width-100 bg-white'}>
            <div className={'flex justify-space-between align-center'}>
                <div className={'font-size-18'}>
                    <div className={'bold'}>{title}</div>
                    <div className={'font-size-16'}>{subtitle}</div>
                </div>
                {link && (
                    <div className={'flex font-size-12 align-center gap-5'}>
                        <Link
                            href={link.url}
                            linkType={'internal'}
                        >
                            <span style={{ color: color('endeavour') }}>{link.text}</span>
                        </Link>
                        <IconImage
                            height={10}
                            url={link.iconUrl}
                            width={10}
                        />
                    </div>
                )}
            </div>
            {topLivePrices && (
                <div
                    className={'width-100 flex align-center gap-5 pointer font-size-12 margin-top-10'}
                    onClick={() => (singleCtaLink ? navigate(singleCtaLink) : setTopLivePriceOpen(!isTopLivePriceOpen))}
                >
                    <HtmlTextWrapper htmlText={topLivePrices.htmlText} />
                    <IconImage
                        height={10}
                        rotate={singleCtaLink ? undefined : isTopLivePriceOpen ? -90 : 90}
                        url={topLivePrices.iconUrl}
                        width={10}
                    />
                </div>
            )}
            {isTopLivePriceOpen && topLivePrices && (
                <div
                    className={'border border-alto border-radius flex justify-space-evenly align-center margin-top-10'}
                    // eslint-disable-next-line no-inline-styles/no-inline-styles
                    style={{ height: 50 }}
                >
                    {topLivePrices.ctaList.map((cta, index) => {
                        return (
                            <React.Fragment key={`${cta.title}-${index}`}>
                                <Link
                                    href={cta.linkUrl}
                                    linkType={'vertical'}
                                >
                                    <div
                                        className={'flex align-center gap-10'}
                                        key={`${cta.title}-${index}`}
                                    >
                                        <div className={'flex gap-10 align-center'}>
                                            <IconImage
                                                height={16}
                                                url={cta.iconUrl}
                                                width={19}
                                            />
                                            <div className={'flex-column justify-center align-center'}>
                                                <HtmlTextWrapper
                                                    className={'font-size-12'}
                                                    htmlText={`${cta.htmlTitle} ${cta.price.htmlText}`}
                                                />
                                                {cta.price.subtitle && (
                                                    <HtmlTextWrapper
                                                        className={'font-size-10'}
                                                        htmlText={cta.price.subtitle}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                {index !== topLivePrices.ctaList.length - 1 && (
                                    <Divider
                                        color={'alto'}
                                        direction={'vertical'}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
            <div
                className={'grid gap-10 margin-top-10'}
                // eslint-disable-next-line no-inline-styles/no-inline-styles
                style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(235px, 1fr))', rowGap: 20 }}
            >
                {tiles.map((tile, index) => (
                    <SpotResultHorizontalDesktop
                        key={index}
                        {...tile}
                    />
                ))}
            </div>
            <Divider
                color={'alto'}
                marginTop={20}
            />
        </div>
    );
}
