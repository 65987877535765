import type { SpotResultsInfoBoxAttributes } from '@/features/spot-results/spot-results-v4/spot-results-v4-type';

import { Button, HtmlTextWrapper } from '@/core/features';

import styles from './spot-results-info-box-desktop.module.scss';

export default function SpotResultsInfoBoxDesktop({ ctaText, htmlText, linkUrl, title }: SpotResultsInfoBoxAttributes) {
    return (
        <div className={`flex-center-vertical ${styles.container}`}>
            <div className={'font-size-24 line-height-28 margin-bottom-10 text-center'}>{title}</div>
            <div className={'font-size-18 line-height-18 margin-bottom-15 text-center'}>
                <HtmlTextWrapper htmlText={htmlText} />
            </div>
            <Button
                className={'padding-left-10 padding-right-10'}
                href={linkUrl}
                linkType={'internal'}
                title={ctaText}
            />
        </div>
    );
}
