import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { PageComponentProps } from '@/features/a-dynamic-page/components/page-component-switch';
import type {
    SpotResultDesktopV4Meta,
    SpotResultsDesktopV4_jsonld_page_read,
    SpotResultsDesktopV4Attributes,
} from '@/features/spot-results/spot-results-desktop-v4/spot-results-desktop-v4-type';

import React from 'react';
import { InView } from 'react-intersection-observer';

import useLoadingIndicator from '@/core/components/loading/use-loading-indicator';
import { LazyComponent } from '@/core/features';
import useComponentDataByIri from '@/core/features/a-component/hooks/use-component-data-by-iri';
import useComponentPagination from '@/core/features/a-component/hooks/use-component-pagination';
import { updateComponentIriParam } from '@/core/features/a-component/services/component-service';
import useWindowResize from '@/core/features/event-listener/use-window-resize';
import { useAtomValue } from '@/core/features/store/atom-store';
import { logMap } from '@/features/map/map-container/map-logger';
import useMapOverlayHistory from '@/features/map/map-container/use-map-overlay-history';
import { mapBoundariesForComponentsAtom } from '@/features/map/map-data-v5/use-map-data-v5';
import SpotResultsDesktopV4LoadingTiles from '@/features/spot-results/spot-results-desktop-v4/spot-results-desktop-v4-loading-tile';
import SpotResultsDesktopV4LoadingTilesHorizontal from '@/features/spot-results/spot-results-desktop-v4/spot-results-desktop-v4-loading-tile-horizontal';
import SpotResultsTileSwitchDesktop from '@/features/spot-results/spot-results-desktop-v4/spot-results-tile-switch-desktop';
import SpotResultsV4LoadingTilesHorizontal from '@/features/spot-results/spot-results-v4/spot-results-v4-loading-tile-horizontal';

export type SpotResultsDesktopV4Props = PageComponentProps<SpotResultsDesktopV4Attributes, SpotResultDesktopV4Meta>;

export default function SpotResultsDesktopV4({
    attributes,
    componentIri,
    componentType,
    meta,
}: SpotResultsDesktopV4Props) {
    const {
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading: isNextPageLoading,
        pages,
    } = useComponentPagination<SpotResultsDesktopV4_jsonld_page_read>(componentIri);

    const { isHorizontalOrientation, spotResults } = React.useMemo(() => {
        const spotResults = pages.flatMap((page) => page.attributes.tiles);
        return {
            isHorizontalOrientation: spotResults.some((tile) => tile.type === 'spotResultHorizontal'),
            spotResults,
        };
    }, [pages]);

    const showLoadingTiles = useLoadingIndicator(isFetchingNextPage || isNextPageLoading);

    const { openOverlay } = useMapOverlayHistory();

    const { windowSize } = useWindowResize({ intialSize: 1920 });
    const isMobileView = windowSize.innerWidth <= 1024;

    const boundarySearchResults = useMapSearchDataByBoundaries<SpotResultsDesktopV4_jsonld_page_read>(
        'spotResultsDesktopV4',
        meta?.boundariesRequestTarget,
    );

    const lazyTiles = boundarySearchResults?.attributes.tiles || spotResults;

    const tiles = lazyTiles.length === 0 ? attributes.tiles : lazyTiles;

    const onMapOpen = (event: React.MouseEvent<HTMLButtonElement>, target: null | string) => {
        event.preventDefault();
        if (!target) {
            return;
        }
        openOverlay({ target, trackingElement: 'map_textlink' });
    };

    const hasTopLivePrices = tiles.some(
        (tile) => tile.type === 'spotResult' && tile.topLivePrices.some((topLivePrice) => !!topLivePrice.price),
    );

    return (
        <div className={`flex flex-column ${isHorizontalOrientation ? 'gap-20' : 'gap-15'}`}>
            <>
                {tiles.map((tile, index) => {
                    const minHeight = (() => {
                        if (tile.type === 'infoBox') {
                            return 283;
                        }
                        if (tile.type === 'infoText') {
                            return 48;
                        }
                        if (tile.type === 'spotResult' && tile.badges?.length > 0) {
                            return 302;
                        }
                        return 302;
                    })();

                    return (
                        <LazyComponent
                            componentIndex={index}
                            componentType={componentType}
                            disableSkeleton={tile.type === 'infoText'}
                            initialInView={index <= 3}
                            key={`${tile.type}-${index}`}
                            minHeight={minHeight}
                        >
                            <>
                                {hasNextPage && index === spotResults.length - 1 && (
                                    <InView
                                        onChange={(inView) => (inView ? fetchNextPage() : undefined)}
                                        rootMargin={'1000px'}
                                        threshold={0}
                                    />
                                )}
                                <SpotResultsTileSwitchDesktop
                                    {...tile}
                                    isMobileView={isMobileView}
                                    onMapOpen={onMapOpen}
                                />
                            </>
                        </LazyComponent>
                    );
                })}
                {(isFetchingNextPage || showLoadingTiles) && (
                    <SpotResultsLoadingTiles
                        hasTopLivePrices={hasTopLivePrices}
                        isHorizontalOrientation={isHorizontalOrientation}
                        isMobileView={isMobileView}
                    />
                )}
            </>
        </div>
    );
}

export const useMapSearchDataByBoundaries = <T extends PageComponent>(
    queryName: string,
    boundariesRequestTarget?: null | string,
) => {
    const mapBoundariesForComponents = useAtomValue(mapBoundariesForComponentsAtom);

    const updatedBoundariesRequestTarget = React.useMemo(() => {
        if (!boundariesRequestTarget) {
            return null;
        }

        logMap('mapBoundariesForComponents', mapBoundariesForComponents);

        if (!mapBoundariesForComponents) {
            return null;
        }

        return updateComponentIriParam('userQuery', boundariesRequestTarget, mapBoundariesForComponents).toString();
    }, [boundariesRequestTarget, mapBoundariesForComponents]);

    const [boundariesRequestTargetDebounced, setBoundariesRequestTargetDebounced] = React.useState<null | string>(null);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setBoundariesRequestTargetDebounced(updatedBoundariesRequestTarget);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [updatedBoundariesRequestTarget]);

    const { data: spotResultsData } = useComponentDataByIri<T>(boundariesRequestTargetDebounced, {
        queryName,
    });

    return spotResultsData;
};

const SpotResultsLoadingTiles = ({
    hasTopLivePrices,
    isHorizontalOrientation,
    isMobileView,
}: {
    hasTopLivePrices: boolean;
    isHorizontalOrientation: boolean;
    isMobileView: boolean;
}) => {
    return isHorizontalOrientation ? (
        isMobileView ? (
            <SpotResultsV4LoadingTilesHorizontal count={10} />
        ) : (
            <SpotResultsDesktopV4LoadingTilesHorizontal count={10} />
        )
    ) : (
        <SpotResultsDesktopV4LoadingTiles
            count={10}
            hasTopLivePrices={hasTopLivePrices}
        />
    );
};
