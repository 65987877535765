import type { SpotResultsInfoTextAttributes } from '@/features/spot-results/spot-results-v4/spot-results-v4-type';

import React from 'react';

import { HtmlTextWrapper, IconImage } from '@/core/features';

import styles from './spot-results-info-text-desktop.module.scss';

export default function SpotResultsInfoTextDesktop({ htmlText, iconUrl }: SpotResultsInfoTextAttributes) {
    return (
        <div className={styles.container}>
            {iconUrl && (
                <IconImage
                    alt={'Info Icon'}
                    height={20}
                    url={iconUrl}
                    width={20}
                />
            )}
            <HtmlTextWrapper
                className={styles.text}
                htmlText={htmlText}
            />
        </div>
    );
}
