import type { SpotResultsTileDesktopAttributes } from '@/features/spot-results/spot-results-desktop-v4/spot-results-desktop-v4-type';

import React from 'react';

import SpotResultDesktop from '@/features/spot-results/spot-results-desktop-v4/spot-result-desktop/spot-result-desktop';
import SpotResultHorizontalContainerDesktop from '@/features/spot-results/spot-results-desktop-v4/spot-result-desktop/spot-result-horizontal-container-desktop';
import SpotResultsInfoBoxDesktop from '@/features/spot-results/spot-results-desktop-v4/spot-results-info-box-desktop/spot-results-info-box-desktop';
import SpotResultsInfoTextDesktop from '@/features/spot-results/spot-results-desktop-v4/spot-results-info-text-desktop/spot-results-info-text-desktop';
import SpotResultHorizontalContainer from '@/features/spot-results/spot-results-v4/spot-result/spot-result-horizontal-container';

export default function SpotResultsTileSwitchDesktop(
    props: SpotResultsTileDesktopAttributes & {
        isMobileView: boolean;
        onMapOpen: (event: React.MouseEvent<HTMLButtonElement>, target: null | string) => void;
    },
) {
    switch (props.type) {
        case 'spotResult':
            return (
                <SpotResultDesktop
                    {...props}
                    onMapOpen={props.onMapOpen}
                />
            );
        case 'spotResultHorizontal':
            return props.isMobileView ? (
                <SpotResultHorizontalContainer
                    {...props}
                    tiles={props.tiles.flatMap((tile) => (tile.images[0] ? { ...tile, image: tile.images[0] } : []))}
                />
            ) : (
                <SpotResultHorizontalContainerDesktop {...props} />
            );
        case 'infoBox':
            return <SpotResultsInfoBoxDesktop {...props} />;
        case 'infoText':
            return <SpotResultsInfoTextDesktop {...props} />;
    }
}
