import React from 'react';

/**
 * This hook is used to force a rerender on mount so the ref gets updated
 *
 * @param ref to make sure this hook is only used in context of a ref to prevent misuse
 */
export const useRefRerender = <T>(_ref: React.RefObject<T>) => {
    const [_, setState] = React.useState(false);
    React.useEffect(() => {
        setState(true);
    }, []);
};
