import { ShimmerBox } from '@/core/features';

export type SpotResultsV4LoadingTilesProps = {
    count?: number;
};

export default function SpotResultsV4LoadingTilesHorizontal({ count = 10 }: SpotResultsV4LoadingTilesProps) {
    return (
        <div className={'width-100 flex-column gap-30'}>
            {Array.from({ length: count }).map((_, index) => (
                <div key={index}>
                    <div className={'flex justify-space-between align-center margin-bottom-10'}>
                        <div className={'gap gap-5'}>
                            <ShimmerBox
                                height={'18.5px'}
                                width={'200px'}
                            />
                            <ShimmerBox
                                height={'14px'}
                                width={'80px'}
                            />
                        </div>
                        <ShimmerBox
                            height={'18.5px'}
                            width={'200px'}
                        />
                    </div>
                    <div className={'width-100 flex bg-white border-radius overflow-hidden'}>
                        <div className={'border-radius'}>
                            <ShimmerBox
                                borderRadius={'0'}
                                height={'220px'}
                                width={'140px'}
                            />
                        </div>
                        <div className={'width-100 flex-column gap-20 padding-10'}>
                            <div className={'gap'}>
                                <ShimmerBox
                                    height={'18.5px'}
                                    width={'200px'}
                                />
                                <ShimmerBox
                                    height={'14px'}
                                    width={'80px'}
                                />
                            </div>
                            <div className={'flex gap-10 align-center'}>
                                <ShimmerBox
                                    height={'30px'}
                                    width={'30px'}
                                />
                                <ShimmerBox
                                    height={'14px'}
                                    width={'50px'}
                                />
                            </div>
                            <div className={'flex-column gap-5'}>
                                <div className={'flex gap-5'}>
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <ShimmerBox
                                            height={'18px'}
                                            key={index}
                                            width={'22px'}
                                        />
                                    ))}
                                </div>
                                <div className={'flex gap-5'}>
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <ShimmerBox
                                            height={'18px'}
                                            key={index}
                                            width={'22px'}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className={'flex-column gap-5'}>
                                <ShimmerBox
                                    height={'20px'}
                                    width={'120px'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
