import type { SpotResultHorizontalTileDesktop } from '@/features/spot-results/spot-results-desktop-v4/spot-results-desktop-v4-type';

import { KeyFactsHorizontal, Link, MediaSwiper, WishlistButton } from '@/core/features';
import SeasonCalendar from '@/features/season-calendar/season-calendar';
import SpotScoreBadgeDesktop from '@/features/spot-score/spot-score-badge-desktop';

export default function SpotResultHorizontalDesktop({
    calendar,
    id,
    images,
    keyFacts,
    linkUrl,
    spotScore,
    subtitle,
    title,
    wishlistButton,
}: SpotResultHorizontalTileDesktop) {
    const disableClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
    };
    return (
        <Link
            className={'width-100'}
            fullHeight={true}
            fullWidth={true}
            href={linkUrl}
            linkType={'internal'}
            qaId={'qa-spot-page-link-desktop'}
        >
            <div
                className={'width-100 relative border-radius overflow-hidden margin-bottom-10'}
                // eslint-disable-next-line no-inline-styles/no-inline-styles
                style={{ height: 230 }}
            >
                <MediaSwiper
                    imageOptions={{
                        height: 300,
                        width: 440,
                    }}
                    items={images}
                    swiperOptions={{
                        debugName: 'SpotResultDesktopV4',
                        loop: true,
                        swiperModuleType: 'gallery',
                    }}
                />
                <div className={'absolute width-100 z-index-1 top-10 left-10'}>
                    <SeasonCalendar
                        fontColor={'white'}
                        hideCalendarTitle={true}
                        rowCount={3}
                        seasonCalendar={calendar.seasonCalendar}
                        title={calendar.title}
                    />
                </div>
                {wishlistButton && (
                    <div className={'flex-center absolute z-index-1 top-10 right-10'}>
                        <WishlistButton
                            isInitiallySelected={wishlistButton.isSelected}
                            type={'transparent'}
                            wishlistId={id}
                        />
                    </div>
                )}
            </div>
            <div className={'flex-column gap-8'}>
                <div className={'flex align-start justify-space-between'}>
                    <div
                        className={'flex-column gap-5'}
                        // eslint-disable-next-line no-inline-styles/no-inline-styles
                        style={{ maxWidth: '85%' }}
                    >
                        <div className={'ellipsis font-size-16 bold'}>{title}</div>
                        {/* reserve whitespace if not available */}
                        <div className={'ellipsis'}>{subtitle ?? '\u00A0'}</div>
                    </div>
                    <SpotScoreBadgeDesktop
                        fontWeight={'bold'}
                        hideScoreDescription={true}
                        onClick={disableClick}
                        placement={'left-start'}
                        qaId={'qa-spot-result-score-badge-desktop'}
                        size={32}
                        spotScore={spotScore}
                    />
                </div>
                <KeyFactsHorizontal
                    bulletAlignment={'vertical-middle'}
                    bulletSpacing={'padding-left-5 padding-right-5'}
                    fontSize={'font-size-12'}
                    isDesktopView={true}
                    keyfacts={keyFacts}
                    lineClamp={'line-clamp-4'}
                    lineHeight={'line-height-16'}
                />
            </div>
        </Link>
    );
}
