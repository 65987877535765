import type { ISpotScore, SpotScoreDetailsAttributes } from '@/features/spot-score/spot-score-type';

import React from 'react';

import { HtmlTextWrapper, IconImage } from '@/core/features';
import { getCdnStaticImageUrl } from '@/core/features/image-v2/image-utils';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './spot-score-overview.module.scss';

const bem = bemModule(styles);

type SpotScoreOverviewType = 'default' | 'tooltip';

interface SpotScoreOverviewProps {
    spotScoreDetails: NonNullable<ISpotScore['details']>;
    type?: SpotScoreOverviewType;
}

export default function SpotScoreOverview({ spotScoreDetails, type = 'default' }: SpotScoreOverviewProps) {
    const { attributes, description, subTitle, title } = spotScoreDetails;

    return (
        <div className={bem(styles.spotScoreOverview, { [type]: true })}>
            <div className={styles.summary}>
                <div className={styles.heading}>
                    <div className={styles.headingLogo}>
                        <IconImage
                            alt={'check24-logo'}
                            height={30}
                            url={getCdnStaticImageUrl('/static-images/check24-logo-v2.svg')}
                            width={117}
                        />
                    </div>
                    <div className={styles.headingLabel}>
                        <span>SpotScore</span>
                    </div>
                </div>
                <span className={bem(styles.title, { [type]: true })}>{title}</span>
                <div className={`ellipsis ${bem(styles.subTitle, { [type]: true })}`}>{subTitle}</div>
                <div className={bem(styles.title, { [type]: true })}>{spotScoreDetails.htmlScoreDescriptionTitle}</div>
                <div className={`ellipsis ${bem(styles.subTitle, { [type]: true })}`}>
                    <HtmlTextWrapper htmlText={spotScoreDetails.htmlScoreDescriptionVerbose} />
                </div>
            </div>
            <div>
                {attributes.map((attribute, index) => {
                    {
                        return (
                            <SpotScoreBar
                                key={attribute.name + index}
                                {...attribute}
                            />
                        );
                    }
                })}
                <div
                    className={bem(styles.describeScoreText, { [type]: true })}
                    data-nosnippet={true}
                >
                    {description}
                </div>
            </div>
        </div>
    );
}

function SpotScoreBar({ name, score, scoreDescription }: SpotScoreDetailsAttributes) {
    const [animatedScore, setAnimatedScore] = React.useState(0);
    React.useEffect(() => {
        setAnimatedScore(score);
    }, [score]);

    return (
        <div className={styles.chart}>
            <div className={styles.barLabel}>
                <span>{name}</span>
                {scoreDescription}
            </div>
            <div className={styles.bar}>
                <div
                    className={styles.barFull}
                    style={{
                        width: `${animatedScore * 10}%`,
                    }}
                />
            </div>
        </div>
    );
}
