import type { ISpotScore } from '@/features/spot-score/spot-score-type';

import React from 'react';

import { HtmlTextWrapper, Tooltip } from '@/core/features';
import { useRefRerender } from '@/core/features/render/use-ref-rerender';
import { bemModule } from '@/core/utils/bem-classname';
import SpotScoreOverview from '@/features/spot-score/spot-score-overview';

import styles from './spot-score-badge-desktop.module.scss';

interface SpotScoreBadgeDesktopProps {
    flip?: boolean;
    fontWeight?: 'bold' | 'normal';
    hideScoreDescription?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    placement: 'bottom-start' | 'left-start';
    qaId?: string;
    scoreMarginBottom?: number;
    size?: 32 | 40;
    spotScore: ISpotScore;
    type?: 'horizontal' | 'vertical';
}

export default function SpotScoreBadgeDesktop({
    flip = true,
    fontWeight = 'normal',
    hideScoreDescription,
    onClick,
    placement,
    qaId,
    scoreMarginBottom,
    size = 40,
    spotScore,
    type = 'horizontal',
}: SpotScoreBadgeDesktopProps) {
    const bem = bemModule(styles);
    const tooltipTriggerTargetRef = React.useRef<HTMLDivElement>(null);

    useRefRerender(tooltipTriggerTargetRef);

    return (
        <div
            className={bem(styles.spotScoreContainer, { [type]: true })}
            onClick={onClick}
            ref={tooltipTriggerTargetRef}
        >
            {!hideScoreDescription && spotScore.htmlScoreDescription && (
                <span className={bem(styles.spotScoreText, { [fontWeight]: true })}>
                    <HtmlTextWrapper htmlText={spotScore.htmlScoreDescription} />
                </span>
            )}
            <div
                className={`flex-center ${styles.scoreBadge}`}
                data-qa-id={qaId}
                style={{
                    height: size,
                    marginBottom: scoreMarginBottom ? `${scoreMarginBottom}px` : undefined,
                    width: size,
                }}
            >
                {spotScore.details ? (
                    <SpotScoreBadgeTooltip
                        flip={flip}
                        placement={placement}
                        spotScore={spotScore}
                        triggerTarget={tooltipTriggerTargetRef}
                    />
                ) : (
                    <span className={styles.spotScore}>{spotScore.scoreText}</span>
                )}
            </div>
        </div>
    );
}

const SpotScoreBadgeTooltip = ({
    flip = true,
    placement,
    spotScore,
    triggerTarget,
}: {
    flip?: boolean;
    placement: 'bottom-start' | 'left-start';
    spotScore: ISpotScore;
    triggerTarget: React.RefObject<HTMLDivElement>;
}) => (
    <Tooltip
        ariaLabel={`spotScore-${spotScore.scoreText}`}
        content={
            spotScore.details && (
                <div className={styles.spotScoreOverviewContainer}>
                    <SpotScoreOverview
                        spotScoreDetails={spotScore.details}
                        type={'tooltip'}
                    />
                </div>
            )
        }
        flip={flip}
        tippyConfig={{ maxWidth: 385, offset: [0, 20], placement: placement, triggerTarget: triggerTarget.current }}
    >
        <span className={styles.spotScore}>{spotScore.scoreText}</span>
    </Tooltip>
);
