import type { BadgeWithTooltipType } from '@/core/components/badges/badge-type';
import type { KeyFactsType } from '@/features/key-facts-v2/key-facts-type';
import type { SeasonCalendarType } from '@/features/season-calendar/season-calendar-type';

import React from 'react';

import { Badges, KeyFactsDesktop } from '@/core/features';
import SeasonCalendar from '@/features/season-calendar/season-calendar';

import styles from './spot-result-info-container-desktop.module.scss';

interface SpotResultInfoContainerDesktopProps {
    badges: BadgeWithTooltipType[];
    calendar: SeasonCalendarType;
    keyFacts: KeyFactsType;
    onBadgeClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export default function SpotResultInfoContainerDesktop({
    badges,
    calendar,
    keyFacts,
    onBadgeClick,
}: SpotResultInfoContainerDesktopProps) {
    const hasBadges = badges.length > 0;
    return (
        <div className={styles.spotTileInfoContainer}>
            <div className={styles.spotTileTextSpotInfoContainer}>
                {hasBadges && (
                    <div className={styles.badges}>
                        <Badges
                            badges={badges}
                            cursor={'cursor-default'}
                            onClick={onBadgeClick}
                            type={'horizontal'}
                            wrap={'wrap'}
                        />
                    </div>
                )}
                <div className={styles.calendarKeyFactText}>
                    <SeasonCalendar
                        rowCount={1}
                        seasonCalendar={calendar.seasonCalendar}
                        title={calendar.title}
                        titleMarginBottom={8}
                    />
                    <KeyFactsDesktop
                        fontSize={'font-size-12'}
                        gap={8}
                        keyFacts={keyFacts}
                        showIcon={false}
                        truncation={'oneLine'}
                    />
                </div>
            </div>
        </div>
    );
}
