import React from 'react';

export default function useLoadingState() {
    const [isLoading, setLoading] = React.useState(false);

    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    React.useEffect(() => {
        if (isLoading === true) {
            timeoutRef.current = setTimeout(() => {
                setLoading(false);
            }, 10000);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [isLoading]);

    return {
        isLoading,
        setLoading,
    };
}
