import Divider from '@/core/components/divider/divider';
import { ShimmerBox } from '@/core/features';

type SpotResultsDesktopV4LoadingTilesHorizontalProps = {
    count?: number;
};

export default function SpotResultsDesktopV4LoadingTilesHorizontal({
    count = 10,
}: SpotResultsDesktopV4LoadingTilesHorizontalProps) {
    return (
        <div className={'width-100 flex-column gap-15'}>
            {Array.from({ length: count }).map((_, index) => (
                <div key={index}>
                    <div className={'width-100 flex-column gap-10'}>
                        <div className={'width-100 flex justify-space-between align-center'}>
                            <div className={'flex-column gap-5'}>
                                <ShimmerBox
                                    height={'25px'}
                                    width={'150px'}
                                />
                                <ShimmerBox
                                    height={'25px'}
                                    width={'150px'}
                                />
                            </div>
                            <ShimmerBox
                                height={'25px'}
                                width={'150px'}
                            />
                        </div>
                        <div
                            className={'grid gap-10 margin-top-10'}
                            // eslint-disable-next-line no-inline-styles/no-inline-styles
                            style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))', rowGap: 20 }}
                        >
                            {Array.from({ length: 4 }).map((_, index) => (
                                <div
                                    className={'width-100'}
                                    key={`tile-${index}`}
                                >
                                    <div className={'border-radius margin-bottom-10'}>
                                        <ShimmerBox height={'230px'} />
                                    </div>
                                    <div className={'width-100 flex justify-space-between align-top margin-bottom-20'}>
                                        <div className={'flex-column gap-5'}>
                                            <ShimmerBox
                                                height={'20px'}
                                                width={'94px'}
                                            />
                                        </div>
                                        <ShimmerBox
                                            height={'32px'}
                                            width={'32px'}
                                        />
                                    </div>
                                    <ShimmerBox height={'29px'} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <Divider
                        color={'gallery'}
                        marginBottom={20}
                        marginTop={20}
                    />
                </div>
            ))}
        </div>
    );
}
