import type { TopLivePrice } from '@/features/top-live-price/top-live-price-type';

import React from 'react';

import Divider from '@/core/components/divider/divider';
import { HtmlTextWrapper, IconImage, Link } from '@/core/features';
import useLinkoutTracking from '@/features/linkout-tracking/use-linkout-tracking';

import styles from './spot-tile-top-live-prices-desktop.module.scss';

type SpotTileTopLivePricesDesktopProps = { livePrices: TopLivePrice[]; trackingTitle: string };
export function SpotTileTopLivePricesDesktop({ livePrices, trackingTitle }: SpotTileTopLivePricesDesktopProps) {
    const { trackLinkout } = useLinkoutTracking();

    const handleTrackLinkout = (linkUrl: string) => {
        trackLinkout({
            elementType: 'spot_tile',
            linkUrl: linkUrl,
            spot: trackingTitle,
        });
    };
    return (
        <div className={`flex-column ${styles.livePrices}`}>
            {livePrices.map((livePrice, index) => {
                return (
                    <React.Fragment key={livePrice.title}>
                        <Link
                            className={styles.livePriceLink}
                            href={livePrice.linkUrl}
                            linkType={'vertical'}
                            onClick={() => handleTrackLinkout(livePrice.linkUrl)}
                            target={'_blank'}
                        >
                            <div className={`flex-column ${styles.livePriceTile}`}>
                                <IconImage
                                    height={25}
                                    url={livePrice.iconUrl}
                                    width={30}
                                />
                                <div className={`flex-column padding-top-10 ${styles.text}`}>
                                    <HtmlTextWrapper
                                        className={'font-size-12'}
                                        htmlText={livePrice.htmlTitle}
                                    />
                                    <HtmlTextWrapper
                                        className={'font-size-10'}
                                        htmlText={livePrice.price.htmlText}
                                    />
                                </div>
                            </div>
                        </Link>
                        {index !== livePrices.length - 1 && (
                            <div className={'padding-x-15 width-100'}>
                                <Divider color={'alto'} />
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
}
