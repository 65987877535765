import type { SpotResultDesktopAttributes } from '@/features/spot-results/spot-results-desktop-v4/spot-results-desktop-v4-type';

import React from 'react';

import useLoadingState from '@/core/components/loading/use-loading-state';
import { Button, Link, MediaSwiper, WishlistButton } from '@/core/features';
import { layoutIdAtom } from '@/core/features/app/app-atoms';
import { useAtomValue } from '@/core/features/store/atom-store';
import { bemModule } from '@/core/utils/bem-classname';
import { trackSpotResultClick } from '@/features/spot-results/spot-result-tracking';
import SpotResultInfoContainerDesktop from '@/features/spot-results/spot-results-desktop-v4/spot-result-info-container-desktop/spot-result-info-container-desktop';
import { SpotTileTopLivePricesDesktop } from '@/features/spot-results/spot-results-desktop-v4/spot-tile-top-live-prices-desktop/spot-tile-top-live-prices-desktop';
import SpotScoreBadgeDesktop from '@/features/spot-score/spot-score-badge-desktop';

import styles from './spot-result-desktop.module.scss';
const bem = bemModule(styles);

export default function SpotResultDesktop({
    badges,
    calendar,
    ctaText,
    destinationTitle,
    id,
    images,
    keyFacts,
    linkUrl,
    mapButton,
    onMapOpen,
    spotScore,
    spotTitle,
    topLivePrices,
    wishlistButton,
}: SpotResultDesktopAttributes & {
    onMapOpen: (event: React.MouseEvent<HTMLButtonElement>, target: null | string) => void;
}) {
    const disableClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
    };

    const { isLoading, setLoading } = useLoadingState();

    const layoutId = useAtomValue(layoutIdAtom);
    const isMapSearchLayout = layoutId?.includes('Map_Search');

    return (
        <div className={'flex gap-5 width-100 min-width-0'}>
            <Link
                className={styles.mainLink}
                fullHeight={true}
                fullWidth={true}
                href={linkUrl}
                linkType={'internal'}
                onClick={() => {
                    setLoading(true);
                    return trackSpotResultClick(spotTitle, { isMap: false });
                }}
                qaId={'qa-spot-page-link-desktop'}
            >
                <div className={bem(styles.imageContainer, { isMapSearchLayout })}>
                    <MediaSwiper
                        imageOptions={{
                            height: 300,
                            width: isMapSearchLayout ? 313 : 440,
                        }}
                        items={images}
                        swiperOptions={{
                            debugName: 'SpotResultDesktopV4',
                            loop: true,
                            swiperModuleType: 'gallery',
                        }}
                    />

                    {wishlistButton && (
                        <div className={`flex-center ${styles.wishListContainer}`}>
                            <WishlistButton
                                isInitiallySelected={wishlistButton.isSelected}
                                type={'transparent'}
                                wishlistId={id}
                            />
                        </div>
                    )}
                </div>
                <div className={bem(styles.contentDetails, { isMapSearchLayout })}>
                    <div className={styles.spotHeaderInfoContainer}>
                        <div>
                            <h2 className={`${styles.headerSpotName} line-clamp-1`}>{spotTitle}</h2>
                            <div className={styles.destinationContainer}>
                                {destinationTitle}
                                {mapButton?.target && (
                                    <>
                                        <span className={styles.point}>•</span>
                                        <button
                                            className={styles.mapButton}
                                            onClick={(event) => onMapOpen(event, mapButton.target)}
                                        >
                                            auf Karte anzeigen
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                        <SpotScoreBadgeDesktop
                            fontWeight={'bold'}
                            onClick={disableClick}
                            placement={'left-start'}
                            qaId={'qa-spot-result-score-badge-desktop'}
                            spotScore={spotScore}
                        />
                    </div>

                    <SpotResultInfoContainerDesktop
                        badges={badges}
                        calendar={calendar}
                        keyFacts={keyFacts}
                        onBadgeClick={disableClick}
                    />

                    <div className={'flex-end width-100'}>
                        <div className={styles.button}>
                            <Button
                                fontSize={16}
                                fontWeight={'bold'}
                                height={40}
                                isLoading={isLoading}
                                title={ctaText}
                            />
                        </div>
                    </div>
                </div>
            </Link>
            {topLivePrices.length > 0 && (
                <div className={styles.livePriceContainer}>
                    <SpotTileTopLivePricesDesktop
                        livePrices={topLivePrices}
                        trackingTitle={spotTitle}
                    />
                </div>
            )}
        </div>
    );
}
